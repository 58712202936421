const numberHelper = {
	formatPrice: (price) => {
		const hasDecimal = price % 1 !== 0
		if (hasDecimal) {
			return price.toFixed(2)
		} else {
			return price
		}
	},
}

export default numberHelper
